import React, { useEffect, useRef, useState } from "react";

import "cropperjs/dist/cropper.css";

import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faCheck,
  faGear,
  faLink,
  faLocationDot,
  faPalette,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Avatar, Badge } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "../index.css";
import CropperModal from "../components/CropperModal";
import { getAllPiecesByArtist } from "../lib/api";
import { generateLatestLocationName, getTotalViews } from "../lib/utils";
import { BsFillEyeFill } from "react-icons/bs";
import { Bounce, toast, ToastContainer } from "react-toastify";
import GroupedPiecesByLocation from "../components/GroupedPiecesByLocation";

interface ArtistBioPageProps {
  isEditable: boolean;
}

const getMostRecentLocation = (locationHistory: any) => {
  return locationHistory.sort(
    // @ts-ignore
    (a: any, b: any) => new Date(b.startDate) - new Date(a.startDate)
  )[0].location;
};

// @ts-ignore
const groupByLocation = (pieces) => {
  const locationMap = new Map();

  // @ts-ignore
  pieces.forEach((piece) => {
    const mostRecentLocation = getMostRecentLocation(piece.locationHistory);
    const locationName = mostRecentLocation.name;

    if (!locationMap.has(locationName)) {
      locationMap.set(locationName, []);
    }
    locationMap.get(locationName).push(piece);
  });

  return Array.from(locationMap.entries()).map(([location, pieces]) => ({
    location,
    pieces,
  }));
};

const ArtistBioPage = ({ isEditable }: ArtistBioPageProps) => {
  const { artistUid } = useParams();
  const topBarRef = useRef(null);
  const [artPieces, setArtPieces] = useState<any>([]);
  const [qrCode, setQrCode] = useState<any>(null);
  const [result, setResult] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<any>({}); // TODO: fix this any
  const [originalUser, setOriginalUser] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [pieces, setPieces] = useState([]);
  const [showCheckmark, setShowCheckmark] = useState(false);

  const triggerModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    console.log(window.history.scrollRestoration);
    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
    if (topBarRef.current) {
      // @ts-ignore
      topBarRef.current.tabIndex = -1;
      // @ts-ignore
      topBarRef.current.focus();
    }
  }, []);

  const navigate = useNavigate();

  const handlePiece = (piece: any) => {
    console.log("piece._id", piece._id);
    navigate(`/home/piece/${piece._id.toString()}`);
  };

  const linkArt = (id: any) => {
    const url = new URL(qrCode);
    // Extract the UUID from the pathname
    const uuid = url.pathname.split("/").pop();
    const newArtPieces = artPieces.map((artPiece: any) => {
      if (artPiece.id === id) {
        artPiece.linkedQRCode = uuid;
      }
      return artPiece;
    });
    setArtPieces(newArtPieces);
  };

  useEffect(() => {
    setQrCode(result);
  }, [result]);

  const getUserByUid = async (uid: string) => {
    try {
      const response = await fetch(
        `https://sparkplug-api.onrender.com/user/${uid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const userResponse = await response.json();
      setUser(userResponse);
      setOriginalUser(userResponse);
      return userResponse;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsLoading(true); // Start loading
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        await getUserByUid(uid);
        await getAllPiecesByArtist(uid).then((data) => {
          console.log("data", data);
          setPieces(data);
        });
      }
    });
    setIsLoading(false);
  }, []);

  const logOutHandler = () => {
    const auth = getAuth();
    auth.signOut().then(() => {
      console.log("Logged out");
      navigate("/");
    });
  };

  const inputStyle = {
    fontSize: "16px",
    padding: "10px",
    margin: "10px 0",
    backgroundColor: "#1f2129",
    color: "white",
    border: "1px solid #444",
    borderRadius: "500px",
    width: "-webkit-fill-available",
    fontFamily: "Arial, sans-serif",
  };

  const descriptionStyle = {
    ...inputStyle,
    borderRadius: "10px",
    height: "100px",
  };

  const getChangedFields = () => {
    const changedFields = {};
    for (const key in user) {
      if (user[key] !== originalUser[key]) {
        // @ts-ignore
        changedFields[key] = user[key];
      }
    }
    return changedFields;
  };

  const saveEdits = async () => {
    try {
      const changes = getChangedFields();
      const response = await fetch(
        `https://sparkplug-api.onrender.com/user/${user.uid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(changes),
        }
      );
      const userResponse = await response.json();
      setUser(userResponse);
      setOriginalUser(userResponse);
      return userResponse;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveCroppedImage = (croppedImage: any) => {
    // Make the API call here to save the image in the backend
    // saveProfilePicture(croppedImage);
    setUser({ ...user, profilePicture: croppedImage });
    setShowCropper(false);
  };

  const groupedPieces = groupByLocation(pieces);

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const notify = () => {
    // Show the checkmark
    setShowCheckmark(true);

    // Hide the checkmark after 3 seconds
    setTimeout(() => {
      setShowCheckmark(false);
    }, 3000);

    copyToClipboard(`https://artlink.network/artist/${user.uid}`);
    toast("Artist link copied to clipboard!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  };

  console.log(user);
  return (
    <div
      style={{
        backgroundColor: "#1f2129",
        color: "white",
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingTop: "25px",
        paddingBottom: "50px",
      }}
    >
      {/* Top Bar */}
      <ToastContainer />
      <div
        ref={topBarRef}
        style={{
          outline: "none",
          display: "flex",
          justifyContent: "space-between", // This will push the icons to each end of the container
          alignItems: "center", // This will vertically align the icons in the middle, if needed
          padding: "10px",
          // width: "100%", // Ensure the container stretches across the full width
        }}
      >
        <FontAwesomeIcon
          size="xl"
          icon={faAngleLeft}
          color="white"
          onClick={() => navigate("/home")}
        />
        <FontAwesomeIcon
          onClick={() => {
            // HOWDY
            // make it bring up the modal
            // port the modal code over - done
            // add the modal state - done
            // apply the closing modal code to the X on the modal
            // show some indicator you're in the view/edit bio
            // add logouthandler to the logout button
            triggerModal();
          }}
          icon={faGear}
          size="2xl"
          style={{ outline: "none", display: isEditable ? "block" : "none" }}
        />

        {/* <div style={{ fontSize: "30px" }}>⚙️</div> */}
      </div>

      {/* Main Content */}
      <div
        style={{
          flex: 1,
          flexDirection: "column",
          margin: "20px",
        }}
      >
        {isEditMode ? (
          <input
            type="text"
            placeholder="Name"
            value={user.displayName}
            style={inputStyle}
            onChange={(e) => {
              setUser({ ...user, displayName: e.target.value });
            }}
          />
        ) : (
          <>
            <div
              style={{
                color: "white",
                fontSize: "30px",
                fontWeight: 900,
                lineHeight: "41.86px",
                wordWrap: "break-word",
              }}
            >
              {user.displayName}
              <FontAwesomeIcon
                onClick={() => notify()}
                icon={showCheckmark ? faCheck : faLink}
                color={"#7800FF"}
                size="sm"
                style={{ alignSelf: "center", marginLeft: "10px" }} // Centers the icon if it's not aligned with the text
              />
            </div>
          </>
        )}
        {isEditMode ? (
          <input
            type="text"
            placeholder="Subheader"
            value={user.subheader}
            style={inputStyle}
            onChange={(e) => {
              setUser({ ...user, subheader: e.target.value });
            }}
          />
        ) : (
          <div
            style={{
              color: "#7800FF",
              fontSize: "16px",
              fontWeight: 900,
              lineHeight: "22.32px",
              wordWrap: "break-word",
            }}
          >
            {user.subheader}
          </div>
        )}

        <div
          style={{
            position: "relative",
            display: "inline-block",
            margin: "10px",
          }}
        >
          <Badge
            count={
              <EditOutlined
                onClick={() => setShowCropper(true)}
                style={{
                  color: "#fff",
                  backgroundColor: "#1890ff",
                  borderRadius: "50%",
                  display: isEditMode ? "block" : "none",
                }}
              />
            }
          >
            <Avatar shape="square" size={151} src={user.profilePicture} />
          </Badge>
        </div>
        <div style={{ width: "100%", height: "100%" }}>
          <span
            style={{
              color: "white",
              fontSize: "18px",
              // fontFamily: "Inter",
              fontWeight: 900,
              textDecoration: "underline",
              lineHeight: "25.11px",
              wordWrap: "break-word",
            }}
          >
            Bio:
          </span>
          <br />
          {isEditMode ? (
            <textarea
              placeholder="Bio"
              value={user.bio}
              style={descriptionStyle}
              onChange={(e) => {
                setUser({ ...user, bio: e.target.value });
              }}
            />
          ) : (
            <span
              style={{
                color: "white",
                fontSize: "18px",
                // fontFamily: "Inter",
                fontWeight: 900,
                lineHeight: "25.11px",
                wordWrap: "break-word",
              }}
            >
              {user.bio}
            </span>
          )}
        </div>
        {artPieces.map((artPiece: any, index: any) => {
          return (
            <div>
              <h4>{`artpiece linkedQRCode: ${
                artPiece.linkedQRCode ?? "null"
              }`}</h4>
              <button
                // disabled={qrCode !== null}
                onClick={() => {
                  linkArt(artPiece.id);
                }}
              >
                Link art
              </button>
            </div>
          );
        })}
        <div style={{ textAlign: "left" }}>
          <div
            style={{
              color: "white",
              fontSize: "30px",
              fontWeight: 900,
              lineHeight: "41.86px",
              wordWrap: "break-word",
              marginTop: "50px",
            }}
          >
            Pieces
          </div>
          <GroupedPiecesByLocation
            groupedPieces={groupedPieces}
            handlePiece={handlePiece}
            getTotalViews={getTotalViews}
          />
        </div>
      </div>

      {/* Bottom Buttons */}
      <div
        style={{
          display: "flex",
          position: "fixed",
          justifyContent: "space-evenly",
          left: 0,
          bottom: 0,
          width: "100%",
          backgroundColor: "#1f2129",
          color: "white",
          textAlign: "center",
          padding: "10px 0", // Adjust as needed
          zIndex: 1000, // Ensure it's above other content
        }}
      >
        {/* Edit Button */}
        {isEditable &&
          (isEditMode ? (
            <button
              onClick={() => {
                saveEdits();
                setIsEditMode(!isEditMode);
              }}
              style={{
                fontWeight: "bold",
                width: "125px",
                backgroundColor: "#7800ff",
                color: "white",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
              }}
            >
              {"Save Edits"}
            </button>
          ) : (
            <button
              onClick={() => {
                setIsEditMode(!isEditMode);
              }}
              style={{
                fontWeight: "bold",
                width: "125px",
                backgroundColor: "#7800ff",
                color: "white",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
              }}
            >
              {"Edit"}
            </button>
          ))}
      </div>
      <CropperModal
        visible={showCropper}
        onClose={() => setShowCropper(false)}
        onSave={handleSaveCroppedImage}
      />
      {showModal && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
            }}
            onClick={() => setShowModal(false)}
          >
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "rgba(31, 33, 40, 0.95)",
                padding: "20px",
                borderRadius: "20px",
                border: "5px solid #7800FF",
                minWidth: "250px",
                zIndex: 1001, // Above the button container
              }}
            >
              <FontAwesomeIcon
                icon={faTimes} // Make sure to import faTimes from '@fortawesome/free-solid-svg-icons'
                onClick={() => setShowModal(false)}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "white",
                  cursor: "pointer",
                }}
              />
              <div id="hi" style={{ marginTop: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column", // Stack buttons vertically
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "#7800ff",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "5px",
                      marginBottom: "50px", // Space between buttons
                      fontWeight: 700,
                      fontSize: "16px",
                      width: "150px",
                      height: "45px",
                    }}
                    onClick={logOutHandler}
                  >
                    Log Out
                  </button>
                  <button
                    style={{
                      backgroundColor: "#7800ff",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "5px",
                      fontWeight: 700,
                      fontSize: "16px",
                      width: "150px",
                      height: "45px",
                      marginBottom: "25px", // Space between buttons
                    }}
                    onClick={() => {
                      // Open up the QR code
                      navigate("/artist-setup-page");
                      setShowModal(false);
                    }}
                  >
                    View/Edit Bio
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ArtistBioPage;
