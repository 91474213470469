import React, { CSSProperties, useEffect, useState } from "react";
import { AiOutlinePicture } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Lottie, { LottieProps } from "react-lottie";
import { check } from "../components/checkmark";
import { Badge, InputNumber, Select, Space, Spin, Switch, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import "./CreateNewPiece.css";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CropperModal from "../components/CropperModal";
import "./InputStyles.css";
import { pieceOptions } from "../lib/utils";
import SideScroller from "../components/SideScroller";
import ImageUpload from "../components/ImageUpload";
import Quantity from "../components/Quantity";

const CreateNewPiece = () => {
  const { currentUser } = useAuth(); // TODO: add number of views and comments

  const [isSaved, setIsSaved] = useState(false);
  const [pieceId, setPieceId] = useState("");
  // TODO: add number of views and comments
  const [startAnimation, setStartAnimation] = useState(false);
  const [locationSearch, setLocationSearch] = useState<any>({});
  const [suggestions, setSuggestions] = useState<any>([]);
  const [hasSelectedLocation, setHasSelectedLocation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isForSale, setIsForSale] = useState(true);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [showCropper, setShowCropper] = useState(false);
  const [hasLocation, setHasLocation] = useState(true);
  const [images, setImages] = useState([
    "https://publicdomainarchive.com/img/c-domain-images-free-stock-photos-high-quality-resolution-downloads-public-domain-archive-6-1000x664_sa5h7xe208pxvz0mk6ptr8.jpg",
    "https://publicdomainarchive.com/img/c-domain-images-free-stock-photos-high-quality-resolution-downloads-public-domain-archive-5-1000x667_dqm4eiixypg7cpuhagaxj5.jpg",
    "https://publicdomainarchive.com/img/c-domain-images-free-stock-photos-high-quality-resolution-downloads-public-domain-archive-4-1000x667_8gk4snyzt8ktkc3xh5xi0e.jpg",
    "https://publicdomainarchive.com/img/free-stock-photos-high-quality-resolution-downloads-public-domain-archive-3-1000x750-170064_1000x675_e7nrk8ytkoyzcwameygpqm.jpg",
  ]);
  const [quantity, setQuantity] = useState(1);
  const [pieceInformation, setPieceInformation] = useState<any>({
    name: "",
    type: "",
    height: "",
    width: "",
    units: "",
    price: "",
    description: "",
    photo: null,
    artistUid: currentUser?.uid,
    location: {},
    quantity: 1,
  });

  const handleDelete = (index: any) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const navigate = useNavigate();
  const inputStyle = {
    fontSize: "16px",
    padding: "10px",
    margin: "10px 0",
    backgroundColor: "#1f2129",
    color: "white",
    border: "1px solid #444",
    borderRadius: "500px",
    width: "-webkit-fill-available",
    fontFamily: "Arial, sans-serif",
  };

  const descriptionStyle = {
    ...inputStyle,
    borderRadius: "10px",
    height: "100px",
  };

  const uploadRectangleStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #444",
    borderRadius: "10px",
    // width: "200px",
    height: "200px",
    cursor: "pointer",
    color: "white",
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
  };

  const iconStyle = {
    fontSize: "48px",
    marginBottom: "10px",
  };

  const dimensionInputStyle = {
    ...inputStyle,
    flex: 1, // Make inputs take equal space
    // margin: "10px", // Adjust based on layout
    width: "90px", // Adjust based on layout
  };

  useEffect(() => {
    if (pieceId) {
      // Start the animation only after the pieceId has been set
      setStartAnimation(true);
    }
  }, [pieceId]); // Dependency array includes pieceId

  const handleSaveButton = async () => {
    // Save quantity to pieceInformation
    setPieceInformation({ ...pieceInformation, quantity: quantity });
    console.log("pieceInformation", pieceInformation);
    if (!pieceInformation.photo) {
      setValidationError("Please upload a photo of the piece.");
      return;
    }
    if (!pieceInformation.name) {
      setValidationError("Please provide a name for the piece.");
      return;
    }
    if (!pieceInformation.units || pieceInformation.units === "") {
      setValidationError("Please provide units for the piece.");
      return;
    }
    if (isForSale && (!pieceInformation.price || pieceInformation.price <= 0)) {
      notifyError();
      setValidationError(
        "Price must be greater than 0 if the piece is for sale."
      );
      return;
    }
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://sparkplug-api.onrender.com/piece/upload/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(pieceInformation),
        }
      );
      if (response.ok) {
        // Check if the response status is 200
        setIsSaved(true); // Update the state to true if the API call is successful
        const result = await response.json();
        setPieceId(result.pieceId);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleFileChange = (event: any) => {
    // if (event.target.files && event.target.files[0]) {
    //   const file = event.target.files[0];
    //   setPieceInformation({ ...pieceInformation, photo: file });
    // }
    // const file = event.target.files[0];
    // console.log("file: ", file);
    // var reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onloadend = () => {
    //   console.log(reader.result);
    //   setPieceInformation({ ...pieceInformation, photo: reader.result });
    // };
    // reader.onerror = () => {
    //   console.error("Error reading the file");
    // };
  };

  const handlePriceChange = (e: { target: { value: any } }) => {
    let inputValue = e.target.value;
    // Add $ if input is not empty and doesn't already include it
    if (inputValue.startsWith("$")) {
      inputValue = inputValue.substring(1);
      setValidationError(null);
    }
    if (!isNaN(inputValue)) {
      const valueWithDollarSign =
        inputValue === "" || inputValue.startsWith("$")
          ? inputValue
          : `$${inputValue}`;
      setPieceInformation({
        ...pieceInformation,
        price: valueWithDollarSign,
      });
    }
  };

  useEffect(() => {
    const fetchPlaces = async () => {
      if (locationSearch.length > 1 && !hasSelectedLocation) {
        try {
          const response = await fetch(
            `https://sparkplug-api.onrender.com/autocomplete?input=${encodeURIComponent(
              locationSearch
            )}`
          );
          if (response.ok) {
            const data = await response.json();
            // Directly set the suggestions with the returned data if it's an array
            if (Array.isArray(data)) {
              setSuggestions(data);
            } else {
              // If data is not an array, log the issue and set suggestions to empty
              console.error("Unexpected response data:", data);
              setSuggestions([]);
            }
          } else {
            throw new Error("Failed to fetch data from the server");
          }
        } catch (error) {
          console.error("Failed to fetch places:", error);
        }
      } else {
        setSuggestions([]);
      }
    };

    if (!hasSelectedLocation) {
      const timeoutId = setTimeout(fetchPlaces, 300);
      return () => clearTimeout(timeoutId);
    }
  }, [locationSearch, hasSelectedLocation]);

  const selectPlace = (place: any) => {
    console.log("place: ", place);
    setPieceInformation({
      ...pieceInformation,
      location: place,
    });
    setLocationSearch(place);
    setSuggestions([]); // Optionally clear suggestions after selection
    setHasSelectedLocation(true);
  };

  const commonInputStyle = {
    fontSize: "16px",
    padding: "10px",
    margin: "10px 0",
    backgroundColor: "#1f2129",
    color: "white",
    border: "1px solid #444",
    borderRadius: "500px",
    width: "-webkit-fill-available",
    fontFamily: "Arial, sans-serif",
  };

  const onLocationSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocationSearch(e.target.value);
    setHasSelectedLocation(false); // Allow searching again after the user starts typing
    // Add the location to the pieceInfomration
  };

  console.log("locationSearch: ", locationSearch);

  const notifyError = () => {
    toast.error("Price must be greater than 0 if the piece is for sale.", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  };

  const handleSaveCroppedImage = (croppedImage: any) => {
    // Make the API call here to save the image in the backend
    console.log("Cropped image:", croppedImage);
    setPieceInformation({ ...pieceInformation, photo: croppedImage });
    setShowCropper(false);
  };

  const formatPrice = (price: number) => {
    if (price) {
      return price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  // Function to calculate 10% of the listing price rounded to 2 decimal places
  const calculateTenPercent = (price: number) => {
    const subtotal = price + 2;
    return (subtotal * 0.1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Function to calculate the subtotal price rounded to 2 decimal places
  const calculateSubtotal = (price: number) => {
    // listing price + application fee

    return (price + 2).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Function to calculate payout for artist rounded to 2 decimal places
  // Which is basically the subtotal without the fees
  const calculatePayout = (price: number) => {
    return (price * 0.9).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const calculateTotalFees = (price: number) => {
    return (2 + price * 0.1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const priceExplanation = (
    <div style={{ textAlign: "left", padding: "10px", minWidth: "200px" }}>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>Listing price:</td>
            <td style={{ textAlign: "right" }}>
              $
              {pieceInformation.price ? formatPrice(pieceInformation.price) : 0}
            </td>
          </tr>
          <tr>
            <td>Application fee:</td>
            <td style={{ textAlign: "right" }}>$2.00</td>
          </tr>

          <tr>
            <td>Subtotal price:</td>
            <td style={{ textAlign: "right" }}>
              $
              {pieceInformation.price
                ? calculateSubtotal(pieceInformation.price)
                : 0}
            </td>
          </tr>
          <tr>
            <td>10% of listing price:</td>
            <td style={{ textAlign: "right" }}>
              $
              {pieceInformation.price
                ? calculateTenPercent(pieceInformation.price)
                : 0}
            </td>
          </tr>
          {/* <tr>
            <td style={{ color: "red" }}>Subtract fees:</td>
            <td style={{ textAlign: "right", color: "red" }}>
              $
              {pieceInformation.price
                ? calculateTotalFees(pieceInformation.price)
                : 0}
            </td>
          </tr> */}
          <tr>
            <td colSpan={2}>
              <br />
            </td>
          </tr>
          <tr>
            <td style={{ color: "lightgreen" }}>Your payout:</td>
            <td style={{ textAlign: "right", color: "lightgreen" }}>
              $
              {pieceInformation.price
                ? calculatePayout(pieceInformation.price)
                : 0}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  const handleImageChange = (imageList: any) => {
    if (imageList.length > 0) {
      const image = imageList[0].data_url;
      setPieceInformation({ ...pieceInformation, photo: image });
      setShowCropper(true); // Show the cropper modal when an image is uploaded
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#1f2129",
        color: "white",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <FontAwesomeIcon
        // left align
        style={{ marginRight: "auto", marginLeft: "10px", marginTop: "10px" }}
        size="xl"
        icon={faAngleLeft}
        color="white"
        onClick={() => navigate("/home")}
      />
      <div
        style={{
          flex: 1,
          flexDirection: "column",
          margin: "20px",
        }}
      >
        <h1>Create New Piece</h1>
        <input
          onChange={(e) => {
            setPieceInformation({
              ...pieceInformation,
              name: e.target.value,
            });
          }}
          type="text"
          placeholder="Name"
          style={inputStyle}
        />
        <Select
          className="pieceType"
          // dropdownStyle={{ backgroundColor: "#1f2129" }}
          mode="tags"
          style={{ ...inputStyle, fontSize: "16px" }}
          placeholder="Select type of art piece"
          // defaultValue={}
          onChange={(e) => {
            setPieceInformation({
              ...pieceInformation,
              type: e,
            });
          }}
          options={pieceOptions}
          optionRender={(option) => (
            <Space>
              <span role="img" aria-label={option.data.label}>
                {option.data.emoji}
              </span>
              {option.data.label}
            </Space>
          )}
        />
        <div
          style={{
            display: "flex",
            margin: "10px 0",
          }}
        >
          <input
            onChange={(e) =>
              setPieceInformation({
                ...pieceInformation,
                height: e.target.value,
              })
            }
            type="tel"
            placeholder="Height"
            style={dimensionInputStyle}
            onInput={(e) => {
              // @ts-ignore
              e.target.value = e.target.value.replace(/[^0-9.]/g, "");
            }}
          />
          <input
            onChange={(e) =>
              setPieceInformation({
                ...pieceInformation,
                width: e.target.value,
              })
            }
            type="tel"
            placeholder="Width"
            style={dimensionInputStyle}
            onInput={(e) => {
              // @ts-ignore
              e.target.value = e.target.value.replace(/[^0-9.]/g, "");
            }}
          />
          <Select
            className="pieceUnit"
            placeholder="Select Unit"
            style={{
              fontSize: "16px",
              padding: "10px",
              margin: "10px 0",
              backgroundColor: "#1f2129",
              color: "white",
              border: "1px solid #444",
              borderRadius: "500px",
              width: "-webkit-fill-available",
              fontFamily: "Arial, sans-serif",
              height: "40.5px",
            }}
            onChange={(e) =>
              setPieceInformation({
                ...pieceInformation,
                units: e,
              })
            }
            options={[
              { value: "in", label: "in" },
              { value: "ft", label: "ft" },
              { value: "mm", label: "mm" },
              { value: "Other", label: "Other" },
            ]}
          />
          {/* <select
            id="choice"
            onChange={(e) =>
              setPieceInformation({
                ...pieceInformation,
                units: e.target.value,
              })
            }
            //@ts-ignore
            placeholder="Units"
            // style={dimensionInputStyle}
            style={
              validationError ? errorDimensionInputStyle : dimensionInputStyle
            }
          >
            <option value="" disabled selected>
              Select unit
            </option>
            <option value="in">inches</option>
            <option value="ft">feet</option>
            <option value="mm">mm</option>
            <option value="other">Other</option>
          </select> */}
        </div>

        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            For Sale?
            <Switch
              onChange={(e) => {
                setIsForSale(e);
                if (e === false) {
                  setPieceInformation({
                    ...pieceInformation,
                    price: null,
                  });
                } else {
                  setPieceInformation({
                    ...pieceInformation,
                    price: "",
                  });
                }
              }}
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked
              style={{ backgroundColor: "#7800FF", marginLeft: "10px" }}
            />
          </div>
          <InputNumber<number>
            disabled={isForSale ? false : true}
            className="ant-input-number"
            style={{ ...inputStyle, color: "white", fontSize: "16px" }}
            // defaultValue={artPieceInformation.price}
            value={isForSale ? pieceInformation.price : "Not for Sale"}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) =>
              value?.replace(/\$\s?|(,*)/g, "") as unknown as number
            }
            placeholder="Set Price"
            onChange={(value) => {
              setPieceInformation({
                ...pieceInformation,
                price: value,
              });
            }}
          />
          <Tooltip placement="left" title={priceExplanation}>
            <InfoCircleOutlined />
          </Tooltip>
        </div>

        {!hasSelectedLocation ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              Location?
              <Switch
                onChange={(checked) => {
                  setHasLocation(checked);
                  setPieceInformation({
                    ...pieceInformation,
                    location: checked ? {} : "",
                  });
                  if (!checked) {
                    setLocationSearch({
                      name: "Artist's Collection",
                    });
                  } else {
                    setLocationSearch({
                      name: "",
                    });
                    setHasSelectedLocation(false);
                  }
                }}
                checkedChildren="Yes"
                unCheckedChildren="No"
                defaultChecked
                style={{ backgroundColor: "#7800FF", marginLeft: "10px" }}
              />
            </div>
            <div style={{ position: "relative", width: "100%" }}>
              <input
                id="location"
                type="text"
                value={
                  hasLocation ? locationSearch.name : "Artist's Collection"
                }
                onChange={onLocationSearchChange}
                style={{ ...commonInputStyle, height: "55px" }}
                placeholder="Set art piece location"
                autoComplete="off"
                disabled={!hasLocation}
              />
              {suggestions.length > 0 && (
                <ul
                  style={{
                    listStyleType: "none",
                    padding: 0,
                    marginTop: "5px",
                    margin: 0,
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    width: "100%",
                    backgroundColor: "#1f2129",
                    zIndex: 1000,
                  }}
                >
                  {suggestions.map((suggestion: any) => (
                    <li
                      key={
                        suggestion.geometry.location.lat +
                        suggestion.geometry.location.lng
                      } // You can generate a unique key like this
                      onClick={() => {
                        selectPlace(suggestion);
                        setSuggestions([]); // Clear suggestions after selection
                      }}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                        backgroundColor: "#333",
                        color: "white",
                        border: "1px solid #444",
                        borderRadius: "5px",
                        margin: "2px 0",
                      }}
                    >
                      {suggestion.name}
                      <br />
                      <i style={{ fontSize: "14px" }}>
                        {suggestion.formatted_address}
                      </i>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ) : (
          <Badge
            count={"X"}
            // @ts-ignore
            onClick={() => {
              setLocationSearch({});
              setHasSelectedLocation(false);
            }}
          >
            <ul style={{ listStyleType: "none", padding: 0, marginTop: "5px" }}>
              <li
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  backgroundColor: "#333",
                  color: "white",
                  border: "1px solid #444",
                  borderRadius: "5px",
                  margin: "2px 0",
                }}
              >
                {locationSearch.name}
                <br />
                <i style={{ fontSize: "14px" }}>
                  {locationSearch.formatted_address}
                </i>
              </li>
            </ul>
          </Badge>
        )}
        <Quantity quantity={quantity} setQuantity={setQuantity} />
        <textarea
          onChange={(e) => {
            setPieceInformation({
              ...pieceInformation,
              description: e.target.value,
            });
          }}
          placeholder="Description"
          style={descriptionStyle}
        />
        <div
          style={uploadRectangleStyle}
          //   @ts-ignore
          onClick={() => setShowCropper(true)}
        >
          {pieceInformation.photo ? (
            <img
              src={pieceInformation.photo}
              alt="Preview"
              style={{
                width: "100%", // Adjust these values as needed
                height: "100%",
                borderRadius: "10px", // To match the uploadRectangleStyle's borderRadius
                objectFit: "cover", // This will ensure the image covers the area without stretching
              }}
            />
          ) : (
            <>
              <div style={iconStyle}>
                <AiOutlinePicture color="gray" />
              </div>
              Upload Photo
            </>
          )}
          {/* <input
            type="file"
            id="hiddenFileInput"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleFileChange}
          /> */}
        </div>
        {/* <ImageUpload onSave={handleSaveCroppedImage} /> */}
        {/* when a piece is saved, show the lottie animation */}
        {isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 100, color: "#7800FF" }}
                  spin
                />
              }
            />
          </div>
        )}
        {/* <div style={{ padding: "20px", backgroundColor: "#333" }}>
          <h1 style={{ color: "white" }}>Side Scroller</h1>
          <SideScroller images={images} onDelete={handleDelete} />
        </div> */}
        {isSaved && pieceId ? (
          <Lottie
            options={{
              loop: false,
              autoplay: startAnimation,
              animationData: check, // Replace check with your imported animation data
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={100}
            width={100}
            eventListeners={
              [
                {
                  eventName: "complete",
                  callback: () => navigate(`/home/piece/${pieceId}`),
                },
              ] as LottieProps["eventListeners"]
            }
          />
        ) : (
          <button
            onClick={handleSaveButton}
            style={{
              display: isLoading ? "none" : "flex",
              justifyContent: "center",
              width: "125px",
              backgroundColor: "#7800ff",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              fontWeight: "bold",
              marginTop: "30px",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "0px",
            }}
          >
            Save
          </button>
        )}
      </div>
      <CropperModal
        visible={showCropper}
        onClose={() => setShowCropper(false)}
        onSave={handleSaveCroppedImage}
      />
      <ToastContainer />
    </div>
  );
};

export default CreateNewPiece;
